const citationOutput = document.getElementById("citationOutput");
const linkButton = document.getElementById("linkButton");
const chicagoButton = document.getElementById("chicagoButton");
const mlaButton = document.getElementById("mlaButton");
const apaButton = document.getElementById("apaButton");
const copyButton = document.getElementById("copyButton");

function updateCitation(style, section) {
    let citationText = "";
    let today = new Date();
    //convert today to August 1, 2021 format
    today = today.toLocaleString('default', { month: 'long' }) + " " + today.getDate() + ", " + today.getFullYear();

    if (style === "link") {
        citationText = '<a href="'+ window.location.href +'">'+section+'</a>';
    } else if (style === "chicago") {
        citationText = "\""+section+".\" PublicRecords.com. Accessed "+ today +". "+ window.location.href +".";
    } else if (style === "mla") {
        citationText = "\""+section+".\" PublicRecords.com, "+ window.location.href +", Accessed "+ today +".";
    } else if (style === "apa") {
        citationText = "\""+section+".\" PublicRecords.com. Retrieved from "+ window.location.href +".";
    }

    citationOutput.value = citationText;
}

function updateSelectedButton(button) {
    const buttons = document.querySelectorAll("#citation-buttons button");
    buttons.forEach(btn => btn.classList.remove("selected"));
    button.classList.add("selected");
}

// Initialize citation
//if id="citation" exists, then initialize citation
if (document.getElementById("citation")) {
    const initialSection = linkButton.getAttribute("data-section");
    updateCitation("link", initialSection);

    linkButton.addEventListener("click", function() {
        const section = linkButton.getAttribute("data-section");
        updateCitation("link", section);
        updateSelectedButton(linkButton);
    });
    chicagoButton.addEventListener("click", function() {
        const section = chicagoButton.getAttribute("data-section");
        updateCitation("chicago", section);
        updateSelectedButton(chicagoButton);
    });
    mlaButton.addEventListener("click", function() {
        const section = mlaButton.getAttribute("data-section");
        updateCitation("mla", section);
        updateSelectedButton(mlaButton);
    });
    apaButton.addEventListener("click", function() {
        const section = apaButton.getAttribute("data-section");
        updateCitation("apa", section);
        updateSelectedButton(apaButton);
    });

    copyButton.addEventListener("click", function() {
        citationOutput.select();
        document.execCommand("copy");

        // Change Copy button text to "Copied!"
        const originalButtonText = copyButton.textContent;
        copyButton.textContent = "Copied!";
        copyButton.disabled = true;
        copyButton.classList.add("selected");

        // Highlight citationOutput
        citationOutput.classList.add("highlighted");

        // After 1.5 seconds, revert back to original text and remove highlighting
        setTimeout(function() {
            copyButton.textContent = originalButtonText;
            copyButton.disabled = false;
            citationOutput.classList.remove("highlighted");
            copyButton.classList.remove("selected");
        }, 1500);
    });
}

